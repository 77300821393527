import { graphql } from "gatsby";
import { ReactElement } from "react";

import HowKnowUs from "../../features/leads-funnel/ui/pages/onboarding/howKnowUs/HowKnowUs";
import OnboardingLayout from "../../organisms/lead/OnboardingLayout";

const HowKnowUsPage = (): ReactElement => {
  return (
    <OnboardingLayout>
      <HowKnowUs />
    </OnboardingLayout>
  );
};

export default HowKnowUsPage;

export const query = graphql`
  query HowKnowUsPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
