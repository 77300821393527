import { Form, FormikHelpers } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";
import * as Yup from "yup";

import Button from "../../../../../../atoms/button/Button";
import RadioButton, {
  RadioButtonVariantsName,
} from "../../../../../../core/ui/atoms/radio-button/RadioButton";
import RadioGroup from "../../../../../../core/ui/molecules/radio-group/RadioGroup";
import { Image } from "../../../../../../core/utils/images";
import PageStepForm, {
  PageStepFormDataElement,
} from "../../../../../../organisms/page-step-form/PageStepForm";
import { Events, track } from "../../../../../../utils/analytics";
import { rollbar } from "../../../../../../utils/rollbar";
import { useLead, useSetLead } from "../../../../application/lead-use-cases";
import { Lead } from "../../../../domain/lead";
import * as styles from "./HowKnowUs.module.scss";

interface HowKnowUsFormValues {
  howKnowUs: string;
}

const magnifier = require("../../../images/magnifier.svg") as Image;

const magnifierSrc: string = magnifier.default;

const HowKnowUs = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const setLead = useSetLead();

  const nextUrl = `/${i18n.language}/onboarding/email/`;

  const HowKnowUsFormData: PageStepFormDataElement = {
    initialValues: {
      howKnowUs: lead.howKnowUs || "",
    },
    validationSchema: Yup.object({
      howKnowUs: Yup.string().required(t("common.validation.required")),
    }),
    handleSubmit: async (
      values: HowKnowUsFormValues,
      { setSubmitting }: FormikHelpers<HowKnowUsFormValues>
    ): Promise<void> => {
      setSubmitting(true);

      if (!setLead) {
        return;
      }

      try {
        await setLead({ howKnowUs: values.howKnowUs });
        track(Events.FORM_ANSWERED, {
          question: "How Know Us",
          answer: values.howKnowUs,
        });
        setTimeout(() => {
          void navigate(nextUrl);
        }, 500);
      } catch (error) {
        rollbar.warn("There was an error setting the lead", error, {
          fingerprint: "onboarding-errors",
          context: "onboarding-how-know-us",
        });
      } finally {
        setSubmitting(false);
      }
    },
    children: (props): ReactElement => {
      const { isSubmitting, isValid, setFieldValue, values } = props;

      const handleChange = (value: string): void => {
        setFieldValue("howKnowUs", value);
      };

      return (
        <Form className={styles.howKnowUsForm}>
          <RadioGroup
            aria-label={t("onboarding.how_know_us_radio_group.aria_label")}
            onChange={(value) => handleChange(value)}
            adoptionClassName={styles.radioGroup}
            {...(lead.howKnowUs?.length && { defaultValue: lead.howKnowUs })}
          >
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <RadioButton
                key={item}
                value={t(`onboarding.how_know_us.answer_${item}`)}
                variantName={RadioButtonVariantsName.hasIcon}
                adoptionClassName={styles.radioButton}
              >
                {t(`onboarding.how_know_us.answer_${item}`)}
              </RadioButton>
            ))}
          </RadioGroup>
          <Button
            type="submit"
            disabled={isSubmitting || !isValid || values.howKnowUs.length === 0}
            isLoading={isSubmitting}
          >
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("onboarding.how_know_us.title")}
      image={magnifierSrc}
      formData={HowKnowUsFormData}
    />
  );
};

export default HowKnowUs;
